
  interface IStoreActivity {
    [key: string]: any;
  }

  import { Emit, Component, Mixins, Prop, InjectReactive, Provide, ProvideReactive } from "vue-property-decorator";

  import ETable from "@/components/ETable.vue";
  
  import { storeAttachmentList, storeAttachmentDetail, storeAttachmentDelete } from "@/api/storeActivity";

  import { cloneDeep } from "lodash";

  import TablePagination from "@/mixins/tablePagination";
  import Resize from "@/mixins/resize";

  @Component({
    components: {
      ETable,
    }
  })
  export default class StoreActivityTable extends Mixins(Resize, TablePagination) {
    @Prop({ default: "" }) keyword: string;
    @Prop({ default: "" }) tabType: string | number;
    @Prop({ default: "" }) activityTypeList: Array<any>;
    @InjectReactive() storeTypeList: Array<any>;

    tableData: Array<IStoreActivity> = [];
    tableLoading = false;

    dialogVisible = false;
    dialogTableList: Array<any> = [];
    columns = [{ label: "门店类型", prop: "storeTypeValue" }, { label: "门店名称", prop: "storeName" }];

    viewDetailLoading = false;

    mounted() {
      this.windowResize(310);
    }

    get attachmentName() {
      const attachmentName = this.activityTypeList[this.tabType]?.dictValue;
      return attachmentName;
    }

    getData() {
      const _type = this.activityTypeList[this.tabType]?.id;
      const params = { keyword: this.keyword, type: _type }
      this.tableLoading = true;
      storeAttachmentList(params).then((res) => {
        this.tableData = res.data || [];
      }).catch(() => {
        this.tableData = [];
      }).finally(() => {
        this.tableLoading = false;
      })
    }

    getStoreTypeLabel(attachmentType) {
      return  this.storeTypeList?.find((el) => el.id == attachmentType)?.dictValue || "-";
    }

    viewStoreList(row) {
      this.dialogVisible = true;

      this.viewDetailLoading = true;
      storeAttachmentDetail({ id: row.id }).then((res) => {
        const { stores } = res.data;
        this.dialogTableList = stores
      }).finally(() => {
        this.viewDetailLoading = false;
      })
    }

    handleClose() {
      this.dialogVisible = false;
      this.dialogTableList = [];
    }

    @Emit("editActivity")
    editActivity(row) {
      return cloneDeep(row)
    }

    storeAttachmentDelete(row) {      
      this.$confirm(`是否删除活动【${row.name}】`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
        }).then(() => {
          storeAttachmentDelete({ id: row.id }).then(() => {
            this.getData();
            this.$message.success("操作成功")
          })
        })
      
    }

  }
