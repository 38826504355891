
  import { Watch, Mixins, Component, Vue, Ref, Provide, ProvideReactive } from "vue-property-decorator";
  import StoreActivityTable from "./component/table.vue";
  import StoreActivityForm from "./component/add.vue";

  import { storeList } from "@/api/store";
  import { getNavList } from "@/api/storeActivity";

  import TablePagination from "@/mixins/tablePagination";
  import Resize from "@/mixins/resize";
  
  @Component({
    inject: [],
    components: {
      StoreActivityTable,
      StoreActivityForm
    }
  })
  export default class StoreActivity extends Mixins(Resize, TablePagination) {
    @Ref("StoreActivityTable") StoreActivityTableList: StoreActivityTable;
    @ProvideReactive() storeList = [];
    @ProvideReactive() storeTypeList = [];
    @ProvideReactive() activityDetail = {};
    @ProvideReactive() activityTypeList = [];
  
    keyword = "";
    currentTab = "0";
    dialogFormVisible = false;
  
    deviceTypeOptions: Array<{ deviceTypeId: number, deviceTypeName: string }> = [];
  
    saveLoading = false;
    formData = {
      productType: 1,
      deviceType: "",
      productName: "",
    }
    formRules = {
      productType: [{ required: true, message: "请选择产品分类" }],
      deviceType: [{ required: true, message: "请选择设备类型" }],
      productName: [{ required: true, message: "请输入产品名称" }],
    }
  
    paginationInfo = {
      currentPage: 1,
      pageSize: 20,
      itemTotal: 0,
    }

    mounted() {
      this.getStoreList();
      this.getNavList();
      this.getNavStoreTypeList();
    }

    editActivity(activityInfo = {}) {
      this.activityDetail = activityInfo;
      this.dialogFormVisible = true;
    }

    refreshTable(index) {
      this.getTabTableData(index);
    }

    getNavList() {
      getNavList("store_attachment_type").then((res) => {
        this.activityTypeList = res.data;
        this.getTabTableData(0);
      })
    }

    getNavStoreTypeList() {
      getNavList("store_type").then((res) => {
        this.storeTypeList = res.data;
      })
    }

    getStoreList() {
      storeList({ pageSize: 9999 }).then((res) => {
        this.storeList = res.data.list || [];
      })
    }

    add() {
      this.dialogFormVisible = true;
      this.activityDetail = {};
    }

    searchData(currentTab) {
      this.StoreActivityTableList[currentTab]?.getData();
    }

    @Watch("currentTab")
    getTabTableData(value) {
      this.keyword = "";
      this.$nextTick(() => {
        this.StoreActivityTableList[value]?.getData();
      })
    }
  
  }
  