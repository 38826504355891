
interface IStoreActivityForm {
  [key: string]: any;
}

import {
  Watch,
  Inject,
  Component,
  Vue,
  PropSync,
  Prop,
  Ref,
  InjectReactive,
  Emit,
} from "vue-property-decorator";
import UploadImg from "@/components/uploadImg.vue";

import { storeAttachmentSave, saveShopRelations, storeAttachmentDetail, storeList } from "@/api/storeActivity";
import { computed } from "vue";


@Component({
  components: {
    UploadImg,
  },
})
export default class StoreActivityForm extends Vue {
  @InjectReactive() activityTypeList: Array<any>;
  @PropSync("formVisible") visible: boolean;
  @Prop({ default: "" }) tabType: string | number;
  // @Prop({ default: () => [] }) storeList: Array<any>;
  @Ref("form") formRef: VForm;
  @Ref("transferRef") transferRef: any;
  @InjectReactive() activityDetail: any;

  form: IStoreActivityForm = {
    name: "",
    storeList: [],
    storeListDesc: "",
    files: [],
    remark: "",
  };

  saveLoading = false;

  storeList = [];
  stashStoreList = [];

  formRules = {
    name: { required: true, message: "请输入价格表名称" },
    files: { required: true, type: "array", message: "请上传图片，1-10张" },
  };

  showTransfer = false;
  storeKeyValueMap = {};

  getDetailLoading = false;

  get getDialogTitle() {
    return `${this.form.id ? '编辑' : '新增'}${this.attachmentName}`;
  }

  addStore() {
    this.showTransfer = true;
    // TODO: 将form.storeList 回显
    this.stashStoreList = this.form.storeList;
    this.transferRef?.clearQuery("left");
    this.transferRef?.clearQuery("right");
  }

  closeTransfer() {
    this.showTransfer = false;
    this.stashStoreList = [];
  }

  confirmTransfer() {
    this.form.storeList = [...this.stashStoreList];
    this.closeTransfer();
  }

  handleClose(): void {
    this.cancel();
  }

  cancel() {
    this.formRef?.resetFields();
    this.form.id = "";
    this.visible = false;
  }

  get attachmentName() {
    const attachmentName = this.activityTypeList[this.tabType]?.dictValue;
    return attachmentName;
  }

  confirm() {
    this.formRef?.validate().then(() => {
      const { id, name, files, remark, storeList } = this.form;
      const attachmentType = this.activityTypeList[this.tabType]?.id;
      const params = { id, name, files: files?.map(file => file.url).join(","), remark, attachmentType }
      this.saveLoading = true;
      storeAttachmentSave(params).then((res) => {
        // 绑定活动的门店
        this.form.id = res.data?.id;
        const params2 = { id: res.data?.id, storeIds: storeList?.length ? storeList.join(",") : null };
        saveShopRelations(params2).then(() => {
          this.$message.success("操作成功");
          this.handleClose();
          this.refreshTable();
        }).finally(() => {
          this.saveLoading = false;
        })
      }).catch(() => {
        this.saveLoading = false;
      })
    });
  }

  @Emit("refreshTable")
  refreshTable() {
    return this.tabType;
  }

  @Watch("visible")
  setForm(flag) {
    if (!flag) return;
    const { id } = this.activityDetail || {};
    const attachmentType = this.activityTypeList[this.tabType]?.id;
    const promise1 = storeList({ type: attachmentType });
    this.getDetailLoading = true;
    if (id) {
      const promise2 = storeAttachmentDetail({ id });
      Promise.all([promise1, promise2]).then(([storeListRes, activityDetailRes]) => {
        const { remark, name, files, stores } = activityDetailRes.data;
        this.storeList = [ ...stores,...storeListRes.data];
        this.setStoreKeyValueMap(this.storeList);
        const fileList = files?.split(",")?.map(el => ({ url: el })) || [];
        Object.assign(this.form, { id, name, remark, files: fileList });
        this.form.storeList = stores?.map(el => el.storeId);
        this.stashStoreList = [...stores?.map(el => el.storeId)]
      }).finally(() => {
        this.getDetailLoading = false;
      })
    } else {
      promise1.then((storeListRes) => {
        this.storeList = [...storeListRes.data];
        this.setStoreKeyValueMap(this.storeList);
        this.stashStoreList = []
      }).finally(() => {
        this.getDetailLoading = false;
      })
    }
  }

  @Watch("form.storeList", { immediate: true, deep: true })
  setStoreListDesc(value) {
    if (!value) return;
    this.form.storeListDesc = value?.map((el) => {
      return this.storeKeyValueMap[el]
    })?.join("/")
  }

  setStoreKeyValueMap(value) {
    if (!value) return;
    this.storeKeyValueMap = {};
    value?.forEach((el) => {
      this.storeKeyValueMap[el.storeId] = el.storeName;
    })
  }
}
