import { service } from "@/ajax/request";

// 获取一级导航列表
export const getNavList = (parentKey) => (
    service({
      url: `/sys/sysDict/listByParentkey/${parentKey}`,
      method: 'POST',
    })
)

export const storeAttachmentList = (params) => (
    service({
      url: `/store/storeAttachment/list`,
      method: 'POST',
      params,
      type: "form"
    })
)

export const storeAttachmentSave = (params) => (
    service({
      url: `/store/storeAttachment/saveOrUpdate`,
      method: 'POST',
      params,
    })
)

export const saveShopRelations = (params) => (
    service({
      url: `/store/storeAttachment/saveShopRelations`,
      method: 'POST',
      params,
      type: "form"
    })
)

export const storeAttachmentDetail = (params) => (
    service({
      url: `/store/storeAttachment/detail`,
      method: 'POST',
      params,
      type: "form"
    })
)

export const storeAttachmentDelete = (params) => (
    service({
      url: `/store/storeAttachment/delete`,
      method: 'POST',
      params,
      type: "form"
    })
)

export const storeList = (params) => (
    service({
      url: `/store/storeAttachment/availableStores`,
      method: 'POST',
      params,
      type: "form"
    })
)